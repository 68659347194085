import * as React from 'react';

import styled from '@emotion/styled';

import { mq } from '@clutter/clean';
import { WwwOneFunnelEntryPoint } from '@root/resources/lead';

import { ServiceImage, ServiceInfo } from './service_info';

import imageForFamilyMoving from '@images_responsive/home/family-moving.jpg';
import imageForMovingCouch from '@images_responsive/home/moving-couch.jpg';

const FamilyMovingImage = styled(ServiceImage)`
  ${mq({
    width: ['245px', null, '429px'],
    alignSelf: ['center', null, 'flex-start'],
  })};
`;

const MovingCouchImage = styled(ServiceImage)`
  opacity: 0.7;

  ${mq({
    width: ['174px', null, '470px'],
    alignSelf: ['center', null, 'flex-end'],
  })};
`;

const CTA = {
  offeringType: 'moving',
  entryPoint: 'moving_cta' as WwwOneFunnelEntryPoint,
};

const FEATURES = [
  {
    name: 'Experienced, friendly movers',
    summary: (
      <>
        Our movers are fully-trained to handle your items with care. They can
        also help pack, if you wish!
      </>
    ),
  },
  {
    name: 'Accurate quote online',
    summary: (
      <>
        Answer a few questions about your upcoming move and you’ll receive a
        quote in minutes.
      </>
    ),
  },
  {
    name: 'A more modern experience',
    summary: (
      <>
        On move day, track your movers’ arrival time online. After, tip movers
        on your phone—no cash needed.
      </>
    ),
  },
];

export const MovingInfo: React.FC = () => (
  <ServiceInfo
    title="Moving"
    subtitle="Reliable movers, transparent pricing"
    cta={CTA}
    features={FEATURES}
    images={
      <>
        <FamilyMovingImage image={imageForFamilyMoving} alt="Family moving" />
        <MovingCouchImage
          image={imageForMovingCouch}
          alt="Person lifting couch"
        />
      </>
    }
    reversed={true}
  />
);
