import React, { useState } from 'react';

import styled from '@emotion/styled';

import {
  Box,
  COLORS,
  FontWeight,
  mq,
  Text,
  UnstyledButton,
} from '@clutter/clean';
import { useLocationLinksQuery } from '@graphql/platform';
import { ServiceAreaMap } from '@root/components/locations/shared/service_area_map';
import { ExpandableCityLinks } from '@root/components/www/home/location_footer/expandable_city_links';
import { ButtonCTA } from '@shared/button_cta';
import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { DropdownArrow } from '@images/svg/dropdown_arrow';

const Headline = styled(Text.Headline)`
  ${mq({
    marginBottom: ['24px', null, '32px'],
  })}
`;

const Stats: React.FC = () => {
  return (
    <Box.Flex flexDirection="column" gap="24px">
      <Box.FlexItem>
        <Text.Title size="medium">6,500 cities</Text.Title>
        <Text.Callout>Clutter is nationwide in USA & Canada</Text.Callout>
      </Box.FlexItem>
      <Box.FlexItem>
        <Text.Title size="medium">
          208,798
          <br />
          customers
        </Text.Title>
        <Text.Callout>Clutter is storing & moving America</Text.Callout>
      </Box.FlexItem>
      <Box.FlexItem>
        <Text.Title size="medium">
          2,000,000 sqft
          <br />
          of storage space
        </Text.Title>
        <Text.Callout>Clutter has you covered</Text.Callout>
      </Box.FlexItem>
    </Box.Flex>
  );
};

export const LocationFooter: React.FC = () => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { isDesktop } = useIsomorphicBreakpoints();
  const { data } = useLocationLinksQuery();

  const states = data?.states ?? [];

  return (
    <Box background={COLORS.moss} color={COLORS.cloud}>
      <Box.Flex
        margin="0 auto"
        maxWidth="1280px"
        flexDirection={['column', null, 'row']}
        padding={['24px', '80px 64px']}
        textAlign={['center', null, 'left']}
        justifyContent="center"
        gap="64px"
      >
        <Box.Flex
          flexBasis="50%"
          flexDirection="column"
          alignItems={['center', null, 'initial']}
        >
          <Text.Title size="large">
            Store & move with
            <br />
            us nationwide
          </Text.Title>
          <Box maxWidth="296px" margin="32px 0 0" width="100%">
            <ButtonCTA
              container="locations_footer"
              label="Get Pricing"
              entryPoint="location_footer_cta"
              style={{ width: '100%' }}
            />
          </Box>
          {isDesktop && expanded && (
            <Box margin="64px 0 0">
              <Stats />
            </Box>
          )}
        </Box.Flex>
        <Box.FlexItem flexBasis="50%">
          <Box.Flex
            flexDirection={['column', null, 'row']}
            justifyContent="space-between"
            gap="32px"
          >
            <Box.FlexItem>
              <Headline weight={FontWeight.Medium}>Smart Storage</Headline>
              <ExpandableCityLinks
                states={states}
                service="storage"
                expanded={expanded}
              />
            </Box.FlexItem>
            <Box.FlexItem>
              <Headline weight={FontWeight.Medium}>Local Moving</Headline>
              <ExpandableCityLinks
                states={states}
                service="moving"
                expanded={expanded}
              />
            </Box.FlexItem>
          </Box.Flex>
          {expanded && (
            <ServiceAreaMap states={states} height={['192px', null, '368px']} />
          )}
          {!isDesktop && expanded && (
            <Box margin="64px 0 0">
              <Stats />
            </Box>
          )}
          <Box margin="48px 0 0">
            <UnstyledButton onClick={() => setExpanded(!expanded)}>
              <Text.Body weight={FontWeight.Medium}>
                See all locations{' '}
                <DropdownArrow
                  fillColor={COLORS.cloud}
                  direction={expanded ? 'up' : 'down'}
                />
              </Text.Body>
            </UnstyledButton>
          </Box>
        </Box.FlexItem>
      </Box.Flex>
    </Box>
  );
};
