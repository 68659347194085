import React from 'react';
import { type FC, useState } from 'react';

import styled from '@emotion/styled';
import YouTube, { type YouTubeEvent, type YouTubeProps } from 'react-youtube';

import { Box, UnstyledButton } from '@clutter/clean';
import { type WTEventParams } from '@clutter/wt';
import { useTrack } from '@root/initializers/wt';
import { type IResponsiveImage } from '@utils/images';

import { ResponsiveImage } from './responsive_image';

import XyJRl54zJdU from '@images_responsive/you_tube/XyJRl54zJdU.jpg';

type YouTubeOpts = YouTubeProps['opts'];

type VideoID = 'XyJRl54zJdU';

const PLACEHOLDERS: Record<VideoID, IResponsiveImage> = {
  XyJRl54zJdU: XyJRl54zJdU,
};

const YouTubePlaySVG = () => (
  <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
    <path
      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
      fill="#f00"
    ></path>
    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
  </svg>
);

const YouTubePlayerContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

const YouTubePlaceholder = styled(ResponsiveImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const YouTubePlayer: FC<{
  title: string;
  videoID: VideoID;
  wtConfig: WTEventParams;
}> = ({ title, videoID, wtConfig }) => {
  const [autoplay, setAutoplay] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ready, setReady] = useState(false);

  const track = useTrack({
    objectType: 'video',
    ...wtConfig,
  });

  const opts: YouTubeOpts = {
    width: '592',
    height: '336',
    playerVars: {
      autoplay,
      host: 'https://www.clutter.com',
    },
  };

  const onPlay = (event: YouTubeEvent) => {
    track({
      action: 'click',
      value: event.data,
      metadata: {
        videoInteraction: 'play',
        videoID: videoID,
        videoHost: 'youtube',
      },
    });
  };

  const onReady = (event: YouTubeEvent) => {
    setReady(true);
    if (autoplay) event.target.playVideo();
  };

  if (process.env.NODE_ENV === 'test') return null;

  return (
    <YouTubePlayerContainer
      onMouseEnter={() => {
        setVisible(true);
      }}
      onClick={() => {
        setAutoplay(true);
        setVisible(true);
      }}
    >
      {visible && (
        <YouTube
          title={title}
          id="yt_video"
          videoId={videoID}
          opts={opts}
          onReady={onReady}
          onPlay={onPlay}
        />
      )}
      {!ready && (
        <Box position="absolute" top={0} left={0} bottom={0} right={0}>
          <YouTubePlaceholder
            alt=""
            loading="lazy"
            image={PLACEHOLDERS[videoID]}
          />

          <Box.Flex
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            justifyContent="center"
            alignItems="center"
          >
            <Box.FlexItem>
              <UnstyledButton
                aria-label="Play"
                style={{ opacity: autoplay ? 0.8 : 1, width: '68px' }}
              >
                <YouTubePlaySVG />
              </UnstyledButton>
            </Box.FlexItem>
          </Box.Flex>
        </Box>
      )}
    </YouTubePlayerContainer>
  );
};
