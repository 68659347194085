import React from 'react';

import styled from '@emotion/styled';

import { COLORS, FontWeight, Text } from '@clutter/clean';
import { CustomLink } from '@shared/custom_link';

const StyledCustomLink = styled(CustomLink)`
  color: ${COLORS.cloud};

  &:hover {
    text-decoration: underline;
  }
`;

export const CityLink: React.FC<{
  to: string;
  cityName: string;
  show?: boolean;
}> = ({ to, cityName, show = true }) => {
  return (
    <Text.Body
      weight={FontWeight.Medium}
      style={{ display: show ? 'block' : 'none' }}
    >
      <StyledCustomLink to={to}>{cityName}</StyledCustomLink>
    </Text.Body>
  );
};
