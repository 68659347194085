import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';

import {
  COLORS,
  mq,
  SERIF_FONT_FAMILY,
  Text,
  toggleStyleValue,
} from '@clutter/clean';
import { Carousel, useCarousel } from '@shared/carousel';
import { TransparentButton } from '@shared/transparent_button';
import { useHasIntersected } from '@utils/hooks';
import { useIsomorphicBreakpoints } from '@utils/hooks/use_breakpoints';

import { Arrow } from '@images/svg/arrow';

const MOBILE_TRANSITION_DURATION = 300; // ms
const DESKTOP_TRANSITION_DURATION = 800; // ms

const NavButton = styled(TransparentButton)`
  width: 56px;
  height: 56px;
  margin: 28px;

  ${mq({
    display: ['none', 'block'],
  })}

  &:hover path {
    fill: ${COLORS.__primaryButtonHover};
  }
`;

const Image = styled.img`
  margin: auto;
  display: block;
  transition: opacity ${DESKTOP_TRANSITION_DURATION}ms ease-in-out;
  border-radius: 8px;
`;

const Content = styled.div`
  transition: opacity ${DESKTOP_TRANSITION_DURATION}ms linear;
  max-width: 480px;
  text-align: center;

  ${mq({
    margin: ['80px 32px 0', '40px 0 0'],
  })}
`;

const Tagline = styled(Text.Title)`
  text-align: center;
  ${mq({
    lineHeight: ['36px', '36px', '41px'],
  })}
`;

const Quote = styled(Text.Body)`
  position: relative;
  margin: 24px 0 16px;
  text-align: left;
`;

const LeftQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  position: absolute;
  left: -24px;
  top: 12px;
  font-size: 40px;
`;

const RightQuote = styled.div`
  font-family: ${SERIF_FONT_FAMILY};
  display: inline-block;
  font-size: 40px;
  line-height: 0;
  transform: translate(9px, 16px);
`;

const Indicator = styled.div<{ focused: boolean }>`
  border-radius: 6px;
  height: 12px;
  margin: 0 6px;
  transition: background ${MOBILE_TRANSITION_DURATION}ms linear;
  width: 12px;

  background: ${toggleStyleValue(
    'focused',
    COLORS.tealJungle,
    COLORS.grayBorder,
  )};
`;

const Indicators = styled.div`
  position: relative;
  justify-content: center;
  height: 0px;
  top: 24px;

  ${mq({
    display: ['flex', 'none'],
  })}
`;

const Container = styled.div`
  text-align: center;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px -24px 0;
`;

const ImageSlider = styled(Carousel)`
  width: 100%;
  max-width: 980px;

  ${mq({
    padding: ['0 80px', 0],
  })}
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 980px;
  margin: auto;
`;

const ContentSlider = styled(Carousel)`
  ${mq({
    // Allows slides to fade in while keeping the travel distance consistent + avoiding cutoff
    padding: ['0', '0 calc((100% - 480px) / 2)'],
  })}
`;

const ImageSlide = styled.div`
  padding: 0 8px;
`;

const Slide = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export type Testimonial = {
  imageUrl: string;
  tagline: React.ReactNode;
  quote: React.ReactNode;
  location: string;
};

export const TestimonialCarousel: React.FC<{ testimonials: Testimonial[] }> = ({
  testimonials,
}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  const { isMobile } = useIsomorphicBreakpoints();
  const focusOffset = isMobile ? 0 : 1;

  const carouselState = useCarousel({
    transitionDuration: isMobile
      ? MOBILE_TRANSITION_DURATION
      : DESKTOP_TRANSITION_DURATION,
  });
  const focusedIdx =
    (carouselState.idx + testimonials.length) % testimonials.length;

  const { targetRef, hasIntersected } = useHasIntersected({
    rootMargin: '50px',
  });

  if (!mounted) {
    return null;
  }

  return (
    <Container ref={targetRef}>
      <ImageContainer>
        <NavButton onClick={carouselState.prev} aria-label="previous slide">
          <Arrow />
        </NavButton>
        <ImageSlider
          slidesToShow={isMobile ? 1 : 3}
          offset={focusOffset}
          {...carouselState}
        >
          {testimonials.map((t, i) => (
            <ImageSlide key={t.imageUrl} aria-hidden={focusedIdx !== i}>
              <Image
                src={hasIntersected ? t.imageUrl : undefined}
                style={{ opacity: focusedIdx === i ? 1 : 0.6 }}
                draggable={false}
                alt=""
              />
            </ImageSlide>
          ))}
        </ImageSlider>
        <NavButton onClick={carouselState.next} aria-label="next slide">
          <Arrow direction="right" />
        </NavButton>
      </ImageContainer>
      <Indicators>
        {testimonials.map((_, i) => (
          <Indicator key={i} focused={i === focusedIdx} />
        ))}
      </Indicators>
      <ContentContainer>
        <ContentSlider slidesToShow={1} {...carouselState}>
          {testimonials.map((t, i) => (
            <Slide key={t.imageUrl}>
              <Content
                style={{
                  opacity: focusedIdx === i ? 1 : 0,
                  transitionDuration: '0.3s',
                  transitionDelay: focusedIdx === i ? '0.3s' : '0.0s',
                }}
                aria-hidden={focusedIdx !== i}
              >
                <Tagline size="small">{t.tagline}</Tagline>
                <Quote>
                  <LeftQuote>“</LeftQuote>
                  {t.quote}
                  <RightQuote>”</RightQuote>
                </Quote>
                <Text.Callout>{t.location}</Text.Callout>
              </Content>
            </Slide>
          ))}
        </ContentSlider>
      </ContentContainer>
    </Container>
  );
};
