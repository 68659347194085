import React from 'react';

import { Box } from '@clutter/clean';
import {
  Location__City,
  Location__Service,
  Location__State,
} from '@graphql/platform';
import { cityURL } from '@root/components/locations/routes';

import { CityLink } from './city_link';

const MAJOR_CITIES_STORAGE_SLUGS = [
  'new-york/new-york',
  'california/los-angeles',
  'california/bay-area',
  'illinois/chicago',
  'texas/austin',
  'florida/miami',
];

const MAJOR_CITIES_MOVING_SLUGS = [
  'new-york/new-york',
  'massachusetts/boston',
  'pennsylvania/philadelphia',
  'illinois/chicago',
  'california/los-angeles',
  'seattle/washington',
];

export const ExpandableCityLinks: React.FC<{
  states: ReadonlyArray<
    Pick<Location__State, 'id' | 'code' | 'name' | 'slug'> & {
      cities: ReadonlyArray<
        Pick<Location__City, 'id' | 'content' | 'name' | 'services' | 'slug'>
      >;
    }
  >;
  service: 'storage' | 'moving';
  expanded: boolean;
}> = ({ states, service, expanded }) => {
  const locationService =
    service === 'moving'
      ? Location__Service.Moving
      : Location__Service.SmartStorage;

  const majorCitySlugs =
    service === 'moving'
      ? MAJOR_CITIES_MOVING_SLUGS
      : MAJOR_CITIES_STORAGE_SLUGS;

  const cities = [];

  for (const state of states) {
    for (const city of state.cities) {
      if (city.services.includes(locationService)) {
        cities.push({
          ...city,
          combinedSlug: `${state.slug}/${city.slug}`,
          state,
        });
      }
    }
  }

  cities.sort((a, b) => {
    const aIndex = majorCitySlugs.indexOf(a.combinedSlug);
    const bIndex = majorCitySlugs.indexOf(b.combinedSlug);
    return (
      (aIndex === -1 ? a.combinedSlug.charCodeAt(0) : aIndex) -
      (bIndex === -1 ? b.combinedSlug.charCodeAt(0) : bIndex)
    );
  });

  return (
    <Box>
      {cities.map((city, index) => (
        <CityLink
          key={`${service}-${city.slug}`}
          to={cityURL(city.state.slug, city.slug, service)}
          cityName={city.name}
          show={index < majorCitySlugs.length || expanded}
        />
      ))}
    </Box>
  );
};
