import React, { useState } from 'react';

import { DateTime } from 'luxon';
import * as qs from 'qs';
import { useLocation } from 'react-router-dom';

import { Box, COLORS } from '@clutter/clean';
import { useReferralQuery } from '@graphql/platform';
import {
  BannerButton,
  BannerContainer,
  BannerContentContainer,
  BannerImageContainer,
} from '@shared/banner';
import { Action, useClientDataContext } from '@shared/client_data_context';
import { InfoTooltip } from '@shared/info_tooltip';
import { useClientDataContextCoupon } from '@utils/hooks/use_client_data_context_coupon';
import { useInferredRegion } from '@utils/hooks/use_inferred_region';

import disappointedImage from '@images/home/disappointed.png';
import tadaImage from '@images/home/tada.png';

const PromotionBanner = () => {
  if (useInferredRegion() === 'Seattle') return null;
  const month = DateTime.local().monthLong;

  return (
    <BannerContainer>
      <Box display={['none', 'block']}>
        <BannerImageContainer height="24px" width="24px">
          <img src={tadaImage} alt="" height={24} width={24} />
        </BannerImageContainer>
      </Box>
      <Box textAlign={['center', 'left']}>
        <BannerContentContainer color={COLORS.moss}>
          We’ll pack & move your stuff into storage for FREE if you book during{' '}
          {month}{' '}
          <InfoTooltip contents="Free pickups are only eligible with a 4 month or 8 month storage term commitment." />
        </BannerContentContainer>
      </Box>
    </BannerContainer>
  );
};

const CouponBanner: React.FC<{}> = () => {
  const { search } = useLocation();
  const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
  const { utm_source: utmSource, utm_content: utmContent } = queryParams;
  // Our moving announcement email shares the referral utm source, ignore fetching referrals for these entries
  const showReferral =
    utmSource === 'referral' && utmContent && utmContent !== 'announce';

  const {
    dispatch,
    data: { referral: contextReferral },
  } = useClientDataContext();

  const { coupon, couponError, expirationDate } = useClientDataContextCoupon();

  const {
    data: referralData,
    loading,
    error: referralError,
  } = useReferralQuery({
    variables: { promoCode: utmContent },
    skip: !showReferral,
    onCompleted: (data) => {
      if (data?.referral) {
        dispatch({
          type: Action.SetReferralPromo,
          payload: { referral: data.referral },
        });
      }
    },
  });
  const referralLoading =
    loading || (!referralData && showReferral && !referralError);
  const referral = referralData?.referral ?? contextReferral ?? undefined;

  const [bannerDismissed, setBannerDismissed] = useState(false);

  const error = utmContent ? referralError : couponError;

  const showBanner =
    (coupon || couponError || showReferral) && !bannerDismissed;

  if (!showBanner) {
    return <PromotionBanner />;
  }

  const couponDiscount = coupon?.percent
    ? `${coupon.percent * 100}%`
    : `$${coupon?.amount}`;

  return (
    <BannerContainer background={error ? COLORS.flower : COLORS.dust}>
      <BannerImageContainer height="24px" width="24px">
        <img src={error ? disappointedImage : tadaImage} alt="" />
      </BannerImageContainer>
      <Box.Flex flexDirection={['column', 'row']}>
        <BannerContentContainer color={COLORS.tealDark}>
          {error ? (
            'Something went wrong. The discount code is no longer available.'
          ) : referralLoading ? (
            'Applying referral gift card...'
          ) : referral ? (
            `Your referral gift card from ${referral.name} has been applied!`
          ) : coupon ? (
            <>
              Save {couponDiscount} TODAY on convenient{' '}
              {coupon.movingEligible && 'moving or'} storage that won’t hurt
              your wallet. Coupon "{coupon.promoCode}" will be applied at
              checkout. {expirationDate && `Offer ends ${expirationDate}.`}
            </>
          ) : (
            'Loading...'
          )}
        </BannerContentContainer>
        <BannerButton
          color={COLORS.storm}
          onClick={() => setBannerDismissed(true)}
        >
          Dismiss
        </BannerButton>
      </Box.Flex>
    </BannerContainer>
  );
};

export { CouponBanner };
